export default () => ({
  message: "",
  mediaFile: "",
  mediaUrl: "",
  mediaType: null,
  documentFile: "",
  documentUrl: "",
  documentName: "",
  documentType: null,
  type: "text"
});
